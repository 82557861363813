var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseSection', {
    attrs: {
      "id": _vm.section.key,
      "is-visible": _vm.isSectionVisible,
      "is-modified": _vm.isDataChanged,
      "section": _vm.section
    },
    on: {
      "onSectionSaveClick": _vm.save
    },
    scopedSlots: _vm._u([{
      key: "section",
      fn: function fn() {
        return [_c('v-card-text', {
          staticClass: "mt-4"
        }, [_c('div', {
          staticClass: "mb-5"
        }, [_c('v-expand-transition', [_c('v-text-field', {
          attrs: {
            "outlined": "",
            "type": "text",
            "placeholder": "ASB, ANZ, KiwiBank",
            "label": "Lender name",
            "disabled": _vm.FIELDS_DISABLED
          },
          model: {
            value: _vm.lender.name,
            callback: function callback($$v) {
              _vm.$set(_vm.lender, "name", $$v);
            },
            expression: "lender.name"
          }
        })], 1), _c('v-expand-transition', [_c('v-text-field', {
          attrs: {
            "outlined": "",
            "type": "number",
            "placeholder": "2.5",
            "label": "Current 1 year fixed rate, %",
            "disabled": _vm.FIELDS_DISABLED
          },
          model: {
            value: _vm.lender.rate,
            callback: function callback($$v) {
              _vm.$set(_vm.lender, "rate", $$v);
            },
            expression: "lender.rate"
          }
        })], 1), _c('v-expand-transition', [_c('v-text-field', {
          attrs: {
            "outlined": "",
            "type": "number",
            "placeholder": "6.94",
            "label": "Stress Rate, %",
            "disabled": _vm.FIELDS_DISABLED
          },
          model: {
            value: _vm.lender.stress_rate,
            callback: function callback($$v) {
              _vm.$set(_vm.lender, "stress_rate", $$v);
            },
            expression: "lender.stress_rate"
          }
        })], 1)], 1)])];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }