<template>
  <BaseSection
    :id="section.key"
    :is-visible="isSectionVisible"
    :is-modified="isDataChanged"
    :section="section"
    @onSectionSaveClick="save"
  >
    <template #section>
      <v-card-text class="mt-4">
        <div class="mb-5">
          <v-expand-transition>
            <v-text-field
              outlined
              type="text"
              v-model="lender.name"
              placeholder="ASB, ANZ, KiwiBank"
              label="Lender name"
              :disabled="FIELDS_DISABLED"
          />
          </v-expand-transition>

          <v-expand-transition>
            <v-text-field
              outlined
              type="number"
              v-model="lender.rate"
              placeholder="2.5"
              label="Current 1 year fixed rate, %"
              :disabled="FIELDS_DISABLED"
          />
          </v-expand-transition>

          <v-expand-transition>
            <v-text-field
              outlined
              type="number"
              v-model="lender.stress_rate"
              placeholder="6.94"
              label="Stress Rate, %"
              :disabled="FIELDS_DISABLED"
          />
          </v-expand-transition>
        </div>
      </v-card-text>
    </template>
  </BaseSection>
</template>

<script>
import { mapGetters } from 'vuex';

import { clone } from '@/utils/object';

import SectionMixin from '@/mixins/SectionMixin';

import BaseSection from '@/components/ApplicationSections/BaseSection.vue';

export default {
  name: 'LenderSection',

  components: {
    BaseSection,
  },

  mixins: [SectionMixin],

  props: {
    existedData: {
      type: Object,
      default() {
        return {};
      },
    },

    section: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isSectionVisible: false,

      initialLender: {},

      lender: {},
    };
  },

  computed: {
    ...mapGetters(['FIELDS_DISABLED']),
  },

  async created() {
    await this.checkIsSectionVisible();

    this.initialLender = clone(this.existedData);
    this.lender = clone(this.existedData);

    this.$emit('componentReady');
  },
};
</script>
